import {Component} from "react";
import {observer} from "mobx-react";
import PropTypes from 'prop-types';

import dynamic from 'next/dynamic';
import {AppContextType, StoreContext} from "../../stores/StoreLoader";
import {sidebarKey} from "../admin/sidebar/SidebarContainer";

@observer
class AllModals extends Component {
    static contextType = StoreContext;

    static getComponent = {
        'news': dynamic(() => import('./NewsModal/NewsModal')),
        'admin': dynamic(() => import('./AdminModal/AdminModal')),
        'login': dynamic(() => import('./LoginModal')),
        'events': dynamic(() => import('./EventModal')),
        'gallery': dynamic(() => import("./SocialItemModal")),
        'sources-modal': dynamic(() => import("./SourcesModal")),
        'edit-hero': dynamic(() => import("./HeroEditor/HeroEditor")),
        'file-preview': dynamic(() => import("./FilePreviewModal")),
        'collectionManagement': dynamic(() => import("./CollectionManagementModal")),
        'peopleCollectionManagement': dynamic(() => import("./PeopleCollectionManagementModal")),
        'schoolBlocksEvent': dynamic(() => import("./SchoolBlocksEventModal")),
        'block': dynamic(() => import("./BlockModal")),
        "schoolDistrict": dynamic(() => import("./SchoolBlocksDistrictSchoolModal"), {ssr: false}),
        "sbOrganization": dynamic(() => import("./SchoolBlocksOrganizationModal/SchoolBlocksOrganizationModal"), {ssr: false}),
        "addOrEditBlock": dynamic(() => import("./AddEditBlockModal/AddOrEditBlock")),
        "toolDrawerGrid": dynamic(() => import("./AddEditBlockModal/ToolDrawerGrid")),
        "addOrEditAlert": dynamic(() => import("./AlertEditModal/AlertEditModal")),
        "helpModal": dynamic(() => import("./HelpModal"), {ssr: false}),
        "permissions": dynamic(() => import("./PermissionsModal/PermissionsModal")),
        "calendarManagement": dynamic(() => import("./CalendarManagementModal/CalendarManagementModal")),
        "calendarList": dynamic(() => import("./CalendarManagementModal/CalendarListModal")),
        "comparePages": dynamic(() => import("./ComparePagesModal")),
        "revertPage": dynamic(() => import("./RevertDialog")),
        "fluxCapacitor": dynamic(() => import("./FluxCapacitorTimeline"), {ssr: false}),
        "googleFilePicker": dynamic(() => import("./GoogleFilePicker")),
        "schoolBlocksPerson": dynamic(() => import("./PersonModal/SchoolBlocksPersonModal")),
        "iconUpload": dynamic(() => import("./IconUpload")),
        "faviconUpload": dynamic(() => import("./IconUpload")),
        "heroImageUpload": dynamic(() => import("./HeroEditor/HeroImageUpload")),
        "statementUpload": dynamic(() => import("./StatementImageUpload/StatementImageUpload")),
        "iosAppInstallHelp": dynamic(() => import("./iosAppInstallHelp")),
        "syncGroupsModal": dynamic(() => import("./SyncGroupsModal/SyncGroupsModal")),
        "notificationChannels": dynamic(() => import("./NotificationChannels/NotificationChannels")),
        "recipientsPreview": dynamic(() => import("./NotificationRecipientsPreviewModal")),
        "brightarrow": dynamic(() => import("./BrightArrowModal")),
        "coursework": dynamic(() => import("./CourseWorkModal")),
        "blockui": dynamic(() => import("./BlockUIModal")),
        "callToActionEditor": dynamic(() => import("./CallToActionEditor/CallToActionEditorModal")),
        "organizationSelection": dynamic(() => import("./OrganizationSelectionModal")),
        "shareContentItem": dynamic(() => import("./ShareContentItemModal")),
        "autoShareManagement": dynamic(() => import("./AutoShareManagementModal")),
        "terraceManagementModal": dynamic(() => import("./TerraceManagement/TerraceManagementModal")),
        "iframe": dynamic(() => import("./IframeModal")),
        "cleverfeedOrgReport": dynamic(() => import("./CleverFeedOrgReportModal")),
        "spotlightTileEditor": dynamic(() => import("./SpotlightTileEditor")),
        "tileImageUpload": dynamic(() => import("./TileImageUpload")),
    };

    static propTypes = {
        modalObj: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            type: PropTypes.string.isRequired,
            theme: PropTypes.oneOf(['classic', 'transparent']),
        }),
    };

    render() {
        const {modalStore} = this.context as AppContextType;

        return (
            <div id="allModalContainer">
                {modalStore.stack.map((modalObj, index) => {

                    if (modalObj.type === sidebarKey) {
                        // we want the sidebar in the modalStack to consume a vertical space, but we don't want
                        // to actually render anything here
                        return <></>
                    }

                    const ModalComponent = AllModals.getComponent[modalObj.type];
                    return (
                        <ModalComponent
                            key={index + modalObj.type}
                            id={modalObj.id}
                            type={modalObj.type}
                            theme={modalObj.theme ? modalObj.theme : 'classic'}
                            index={index}
                            modalObj={modalObj}
                        />
                    );
                })}
            </div>
        );
    }
}

export default AllModals
